<template>
    <div class="select-bills">
        <div :class="{ 'is-loading': state.loading }">
            <div class="header">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="text-right mb-1 pt-2 px-0 col-md-10">
                            {{ lang('Credit Balance') }}: {{ money(wallet_details.wallet_balance) }}
                            <a href="#" @click.prevent="closeApp('gopay/reload/main')" class="text-info">
                                <font-awesome-icon icon="plus-circle" size="lg"></font-awesome-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bills utilities">
                <div v-for="(utilities, i) in utilities_by_category" :key="i" class="d-block px-0">
                    <div class="category-name">{{ utilities[0].category_ui }}</div>
                    <div class="row no-gutters">
                        <div v-for="utility in utilities" class="col-lg-6" :key="utility.type +'-'+ utility.id">
                            <div class="d-flex" @click="!utility.is_maintenance || utility.is_maintenance_whitelist ? payBills(utility) : ''">
                                <div class="image merchant" :style="'background: url('+ utility.logo_url +');'"></div>
                                <div class="name">
                                    <div>
                                        <b>{{ utility.name }}</b><br>
                                        <b>{{ utility.short_name }}</b>
                                    </div>
                                </div>
                                <div class="button">
                                    <button v-if="utility.is_maintenance && utility.is_maintenance_whitelist" class="btn btn-primary rounded-pill btn-sm ml-auto mr-1 mr-sm-3 ws-pre">{{ lang('Maintenance') }}</button>
                                    <button v-else-if="utility.is_maintenance" class="btn btn-primary rounded-pill btn-sm ml-auto mr-1 mr-sm-3 ws-pre" disabled>{{ lang('Maintenance') }}</button>
                                    <button v-else class="btn btn-outline-primary rounded-pill btn-sm ml-auto mr-1 mr-sm-3 ws-pre">{{ lang('Pay Now') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="utilities_by_category.length == 0 && state.loading" class="text-center pt-3">
                {{ lang('loading...') }}
            </div>
            <div v-else-if="false" class="text-center info-color py-4">
                {{ lang('No available location') }}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            utilities_by_category: [],
            wallet_details: {},
        }
    },

    methods: {
        getData() {
            this.$set(this.state, 'loading', true)
            axios.get(this.GLOBAL.API_BASE_URL +"utilities")
                .then(response => {
                    this.utilities_by_category = response.data
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        getCustomerWalletDetails() {
            this.$set(this.state, 'loading_wallet', true)
            axios.get(this.GLOBAL.API_BASE_URL +"customer/wallet-details")
                .then((response) => {
                    this.wallet_details = response.data;
                })
                .finally(() => this.$set(this.state, 'loading_wallet', false));
        },

        payBills(utility) {
            this.$router.push({ name: 'utilities.details', params: {utility:utility}})
        }
    },

    created() {
        this.getData()
        this.getCustomerWalletDetails()
    },

    beforeRouteLeave(to, from, next) {
        if(to.name == 'auth' || to.name == 'bills.payment-status-gopay' || to.name == 'bills.payment-status-fpx') {
            this.closeApp()
        } else {
            next()
        }
    }
}
</script>

<style lang="scss" scoped>
.bills.utilities {

    .category-name {
        background-color: #deebf7;
        color: #2070c0;
        padding: 0.3rem 0.8rem;
        margin-top: 1rem;
    }

    .name,
    .image,
    .button {
        border-top: unset;
    }

    .name {
        min-width: 40%;
    }
}

</style>